html {
  margin: 0;
  /* font-family: lust, serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 18px;
}

canvas {
  z-index: 100 !important;
}

a {
  color: #FFCE00 !important;
}

img {
  pointer-events: none;
	width: 80%;
	height: 80%;
}

.logo-png {
  width: 65%;
  padding: 15px;
}

.footer-icon {
  color: #FFCE00 !important;
  margin: 0 5px;
}

.hero-photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 200px;
  border: 5px solid #FFCE00;
}

#nav-logo {
  z-index: 1000;
  max-width: 200px;
}

.nav-link {
  z-index: 1000;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.nav-link:hover {
  transition: 0.15s;
  padding-top: 11.5px;
  border-top: 3px solid #FFFFFF;
  border-bottom: 3px solid #FFFFFF;
}

.nav-link:active {
  border-top: 6px solid #FFCE00;
  border-bottom: 6px solid #FFCE00;
  padding-top: 8px;
}

.my-card {
  margin: 20px;
}

.my-card-image {
  border-radius: 10px;
  width: 300px;
  height: 480px;
  box-shadow: 0px 3px 3px #ccc;
}

.my-card-title {
  font-size: 24px;
  margin: 0px;
}

.my-card-sub-title {
  font-size: 16px;
  margin: 0px;
}

.my-card-info {
  margin-top: 10px;
  min-height: 100px;
}

.my-card-link {
  font-size: 15px;
}

#my-main-wrapper {
  width: 100vw;
  min-height: 92.3vh;
  flex: 1 0 auto;
  font-family: novel-sans-pro, serif;
}

#navbar {
  background-color: #111111;
}

#my-footer {
  min-height: 7.555vh;
  flex-shrink: none;
  background-color: #111111;
  position: relative;
}

#footer-bg {
  position: absolute !important;
  bottom: 0 !important;
}

.footer-link {
  z-index: 1000;
}

.font-lust {
  font-family: lust, serif;
  font-weight: 400;
  font-style: normal;
}

.font-vortice {
  font-family: vortice-concept,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-novel-sans {
  font-family: novel-sans-pro, sans-serif;
  font-weight: 200;
  font-style: normal;
}

.font-filicudi {
  font-family: filicudi-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-alfarn {
  font-family: alfarn,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-carlmarx-bold {
  font-family: carlmarx,sans-serif;
  font-weight: 700;
  font-style: normal;
}

.font-carlmarx-light {
  font-family: carlmarx,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-joschmi {
  font-family: joschmi,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-reross-quad {
  font-family: reross-quadratic,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-reross-rect {
  font-family: reross-rectangular,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-xants {
  font-family: xants,sans-serif;
  font-weight: 400;
  font-style: normal;
}

.title {
  font-size: 3rem;
  font-weight: bolder;
}

.subtitle {
  font-size: 1.8rem;
  font-weight: lighter;
}

::-webkit-scrollbar {display:none;}

/* SALVINIFICATION */

.salvini-face {
  position: relative;
  left: -5px;
  width: 200px;
  height: 200px;
  z-index: 1000;
}

.flag {
  min-height: 15px;
}

/* SLOT */

.SlotMachine {
  margin-top: -180px;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

#body-slot {
  position: relative;
  width: 400px;
  height: 650px;
  margin-left: auto;
  margin-right: auto;
}

#face-slot {
  position: relative;
  width: 400px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}

.face-section {
  width: 400px;
  height: 200px;
  position: absolute;
  top: 175px;
  overflow: hidden;
  color: white;
  font-family: sans-serif;
  text-align: center;
  font-size: 25px;
}

.body-section {
  width: 400px;
  height: 650px;
  position: absolute;
  overflow: hidden;
  color: white;
  font-family: sans-serif;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: default;
}

.slot-container {
  position: absolute;
  top: 2px;
  width: 100%;
  height: 650px;
  transition: top ease-in-out 0.5s;
  text-align: center;
}

.roll-face {
  background-color: #28A745;
}

.roll-body {
  background-color: #FFFFFF;
}

.roll {
  background-color: #DC3545;
}

.roll, .roll-face, .roll-body, .screenshot {
  width: 215px;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  border-radius: 20px;
  border: 3px solid black;
}

.rolling {
  animation: blinkingText 1.7s infinite;
}

.screenshot {
  display: none;
  background-color: grey;
}

.screenshot-label {
  display: none;
  font-size: 10px;
  padding: none;
  margin: none;
}

#screenshot-name {
  display: none;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }
  49% {
    color: #000;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #000;
  }
}

/* AURORA */

.aurora-pic {
  width: 100%;
  height: 100%;
  max-height: 1200px;
  object-fit: cover;
}